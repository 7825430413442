// Public types related to Index
import { FPDoor, FPFloor, FPPortal, FPRoom, FPWall, IPoint } from '../services/FloorPlanData.public.interfaces'
import { FloorPlanData } from '../services/FloorPlanDataService'
import { Feature, LineString, Point, Polygon } from 'geojson'

export type Annotation = PolygonAnnotation | PointAnnotation | LineAnnotation

/**
 * Required configuration values that must be passed to a Index react component.
 */
export enum FloorPlanEnvironment {
    STAGING = 'https://floor-plan.staging.weworkers.io',
    PRODUCTION = 'https://floor-plan.weworkers.io',
    RECORD_MODEL_STAGING = 'https://cp-devicekit-rm-floor-plan-service-service-0.phoenix.dev.wwrk.co',
    RECORD_MODEL_PRODUCTION = 'https://cp-devicekit-rm-floor-plan-service-service-1.hydra.prod.wwrk.co'
}

export enum FloorPlanBaseMapStyle {
    DEFAULT,
    REVIT,
    ONDEMAND3D
}

export interface FloorPlanConfig {
    environment: FloorPlanEnvironment
    auth0TokenProvider: () => Promise<string>
    baseMapStyle?: FloorPlanBaseMapStyle
    includeInventoryData?: boolean
    displayUnapprovedLatestHarvestData?: boolean
}

export interface FloorPlanLoadEvent {
    error: any | null
    floorPlan: FloorPlanData | null
}

/**
 * The map features present at `point` in a `FloorPlanEvent`.
 */
export interface EventFeatures {
    floor: FPFloor | null
    doors: FPDoor[]
    portals: FPPortal[]
    rooms: FPRoom[]
    walls: FPWall[]
    annotations: Annotation[]
}

/**
 * An event provided to the `onClick` and `onMouseMove` callbacks of the main Index component.
 */
export interface FloorPlanEvent {
    features: EventFeatures
    point: IPoint
    originalEvent: MouseEvent
}

/**
 * Provides a list of annotations present at the current user mouse location and requests
 * a list of annotation ids to drag. Return [] or null to cancel dragging.
 */
export interface FloorPlanShouldEvent {
    annotations: Annotation[]
    point: IPoint
    originalEvent: MouseEvent
}

/**
 * User drag state
 */
export enum DragType {
    START = 'START',
    MOVE = 'MOVE',
    END = 'END'
}

/**
 * A drag event provided to the `onAnnotationDrag` callback of the main Index component.
 */
export interface FloorPlanDragEvent {
    type: DragType
    annotations: Annotation[]
    originalEvent: MouseEvent
}

export interface FloorPlanCamera {
    center: IPoint
    zoom: number
    offsetToMapPoint: (x: number, y: number) => IPoint
    mapPointToOffset: (point: IPoint) => { x: number; y: number }
}

// Annotations
/**
 * Required style attributes to be passed with each PolygonAnnotation.
 */
export interface PointStyle {
    icon: string
}

/**
 * A polygon map annotation to display on the map.
 */
export interface PointAnnotation extends Feature<Point> {
    id: string
    type: 'Feature'
    geometry: {
        type: 'Point'
        coordinates: number[]
    }
    properties: {
        style: PointStyle
        selectedStyle?: PointStyle
        label?: string
        [x: string]: any
    }
}

/**
 * A polygon map annotation to display on the map.
 */
export interface LineAnnotation extends Feature<LineString> {
    id: string
    type: 'Feature'
    geometry: {
        type: 'LineString'
        coordinates: number[][]
    }
    properties: {
        style: PolygonStyle
        selectedStyle?: PolygonStyle
        hoverStyle?: PolygonStyle
        isSelected?: boolean
        label?: string
        [x: string]: any
    }
}

/**
 * Required style attributes to be passed with each PolygonAnnotation.
 */
export interface PolygonStyle {
    fill?: {
        color?: string
        opacity?: number
    }
    line?: {
        color?: string
        width?: number
        opacity?: number
    }
}

/**
 * PolygonStyle objects defining how a polygon feature is displayed during various map interactions.
 */
export interface PolygonAnnotationStyle {
    style?: PolygonStyle
    selectedStyle?: PolygonStyle
    hoverStyle?: PolygonStyle
}

export interface PolygonAnnotationProperties extends PolygonAnnotationStyle {
    underRoomLabels?: boolean
    label?: string
    [x: string]: any
}

/**
 * A polygon map annotation to display on the map.
 */
export interface PolygonAnnotation extends Feature<Polygon> {
    id: string
    type: 'Feature'
    geometry: {
        type: 'Polygon'
        coordinates: number[][][]
    }
    properties: PolygonAnnotationProperties
}
