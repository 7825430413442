export default {
    version: 8,
    name: 'WeWork Default',
    metadata: {
        'mapbox:autocomposite': false,
        'mapbox:type': 'template',
        'mapbox:sdk-support': {
            js: '0.54.0',
            android: '7.4.0',
            ios: '4.11.0'
        },
        'mapbox:groups': {
            '5a748c1569bb89783d136cc6bd83d613': {
                name: 'RoomFillPatterns-ProgramType',
                collapsed: false
            },
            '6cfcd89911dc2033e0f93586b87e51f0': {
                name: 'DB',
                collapsed: false
            },
            e6dc300f513ebf91a9c9e623860f05fc: {
                name: 'IT_Room',
                collapsed: false
            },
            '51a9780c6f2fc84115f8d7b09c3239dd': {
                name: 'FB',
                collapsed: false
            },
            '877d4d8e43d05cbf88c40c55d3f0f71d': {
                name: 'Elevator_Foyer',
                collapsed: false
            },
            '4acbb7fb8b2bd4049d998e68973ab438': {
                name: 'We_Store',
                collapsed: false
            },
            d11c826077e85a73aadc5c81312589c6: {
                name: 'Pantry',
                collapsed: false
            },
            '430dba6ff2d19364e6d8f173d06ca773': {
                name: 'Mothers Room',
                collapsed: false
            },
            '8213dbbd4e9ffc500a884e1eef918b12': {
                name: 'Room Riser',
                collapsed: false
            },
            c0a4d0aa28b67eeda8d816fce2cb31cf: {
                name: 'Mail_Room',
                collapsed: false
            },
            '53883104753f3559bba86b71d2cbf145': {
                name: 'Lounge',
                collapsed: false
            },
            '8cfca2675788397a1082a6f8775bf649': {
                name: 'Private Office',
                collapsed: false
            },
            '92e497aa870def26f93d3fa866e11ece': {
                name: 'WC_Women',
                collapsed: false
            },
            '7a4260d7109b8fd8d83f8fc0abe6a28c': {
                name: 'Hot Desks',
                collapsed: false
            },
            '1680693472e051aee8993f3a0d12a48e': {
                name: 'Firestairs',
                collapsed: false
            },
            '11929aae82a76fdb7dfe40de438fc857': {
                name: 'Booths',
                collapsed: false
            },
            '9d21e39757af486eeb3268b98af94d35': {
                name: 'WC_Mens',
                collapsed: false
            },
            '70568561705d5d36471dfe4369b74396': {
                name: 'Ahu_Room',
                collapsed: false
            },
            '19b159ff9a516054e7c517a1239b9111': {
                name: 'Service Lift',
                collapsed: false
            },
            '36f698ccdf239136da397403c3cc560a': {
                name: 'Hallways',
                collapsed: false
            },
            '02f569fd439fef0b16815a0ed9e36206': {
                name: 'AV-Rooms',
                collapsed: false
            },
            ad6260d670234c8432491af7520ac446: {
                name: 'Printer Room',
                collapsed: true
            },
            '1fae9e639af1d6106ac43f1b8387dc94': {
                name: 'Chairs',
                collapsed: true
            },
            '6bab769fa3956f8b699716ba1875ceb0': {
                name: 'Hot desk labels',
                collapsed: false
            },
            '4a89df1c68e241a788f68904c1d7da59': {
                name: 'Trash',
                collapsed: false
            },
            d3680f7f51847c7e4bd91d2a0ce94947: {
                name: 'Community Bar',
                collapsed: false
            },
            b2996ff1874528407b6f7eebdec5fa50: {
                name: 'Meter',
                collapsed: false
            },
            f2a5f54404a391f1d73f1752614e2cb2: {
                name: 'Service_Corridor',
                collapsed: false
            },
            '3a9e452352a81fdc646f699c560a5bee': {
                name: 'Tables',
                collapsed: false
            },
            '73887ef967fbaf3abc8fac4a220398e6': {
                name: 'RoomFill-ProgramType',
                collapsed: false
            }
        },
        'mapbox:uiParadigm': 'layers',
        'mapbox:trackposition': false
    },
    center: [0.0002283879715605508, 0.00013831753844328887],
    zoom: 19.432330297085862,
    bearing: 0,
    pitch: 5,
    light: { anchor: 'map', color: 'hsl(0, 0%, 0%)' },
    sources: {
        composite: {
            url: 'mapbox://telkowework.ck18a32sk0fer2oo2u9us5ilr-2qc1o,telkowework.ck18abaqr1uu82npk29n6gntf-5pu8z,telkowework.ck18adhy009x22im84gqp0m2f-1rtb3,telkowework.ck55ur7f30ya52nqm38jy5au8-3llpf,telkowework.ck560d0x601252ipfy4a86fp8-1zw0v',
            type: 'vector'
        },
        'mapbox://telkowework.ckk4ggu341mhq20lcaxsmr21i-9rpms': {
            url: 'mapbox://telkowework.ckk4ggu341mhq20lcaxsmr21i-9rpms',
            type: 'vector'
        },
        'mapbox://telkowework.ck18abaqr1uu82npk29n6gntf-7ol5j': {
            url: 'mapbox://telkowework.ck18abaqr1uu82npk29n6gntf-7ol5j',
            type: 'vector'
        },
        'mapbox://telkowework.ck18abaqr1uu82npk29n6gntf-7pn21': {
            url: 'mapbox://telkowework.ck18abaqr1uu82npk29n6gntf-7pn21',
            type: 'vector'
        },
        'mapbox://telkowework.ckk4ggu341mhq20lcaxsmr21i-1r41w': {
            url: 'mapbox://telkowework.ckk4ggu341mhq20lcaxsmr21i-1r41w',
            type: 'vector'
        }
    },
    sprite: 'mapbox://sprites/telkowework/ckp31wxbv6z9017ozgnis42fq/draft',
    glyphs: 'mapbox://fonts/telkowework/{fontstack}/{range}.pbf',
    layers: [
        {
            id: 'background',
            type: 'background',
            paint: { 'background-color': '#f7f7f7', 'background-opacity': 0 }
        },
        {
            id: 'WSFloorFill',
            type: 'fill',
            source: 'composite',
            'source-layer': 'SF36Floors',
            layout: {},
            paint: {
                'fill-color': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    [
                        'case',
                        ['has', 'isSelected'],
                        [
                            'case',
                            ['has', 'overrideSelectedFillColor'],
                            ['get', 'overrideSelectedFillColor'],
                            'hsl(0, 0%, 60%)'
                        ],
                        [
                            'case',
                            ['has', 'overrideHoverFillColor'],
                            ['get', 'overrideHoverFillColor'],
                            'hsl(0, 0%, 60%)'
                        ]
                    ],
                    [
                        'case',
                        ['has', 'isSelected'],
                        [
                            'case',
                            ['has', 'overrideSelectedFillColor'],
                            ['get', 'overrideSelectedFillColor'],
                            'hsl(0, 0%, 60%)'
                        ],
                        ['case', ['has', 'overrideFillColor'], ['get', 'overrideFillColor'], 'hsl(0, 0%, 60%)']
                    ]
                ],
                'fill-outline-color': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    [
                        'case',
                        ['has', 'isSelected'],
                        [
                            'case',
                            ['has', 'overrideSelectedFillColor'],
                            ['get', 'overrideSelectedFillColor'],
                            'hsl(0, 0%, 60%)'
                        ],
                        [
                            'case',
                            ['has', 'overrideHoverFillColor'],
                            ['get', 'overrideHoverFillColor'],
                            'hsl(0, 0%, 60%)'
                        ]
                    ],
                    [
                        'case',
                        ['has', 'isSelected'],
                        [
                            'case',
                            ['has', 'overrideSelectedFillColor'],
                            ['get', 'overrideSelectedFillColor'],
                            'hsl(0, 0%, 60%)'
                        ],
                        ['case', ['has', 'overrideFillColor'], ['get', 'overrideFillColor'], 'hsl(0, 0%, 60%)']
                    ]
                ],
                'fill-opacity': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['case', ['has', 'overrideSelectedFillOpacity'], ['get', 'overrideSelectedFillOpacity'], 1],
                        ['case', ['has', 'overrideHoverFillOpacity'], ['get', 'overrideHoverFillOpacity'], 1]
                    ],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['case', ['has', 'overrideSelectedFillOpacity'], ['get', 'overrideSelectedFillOpacity'], 1],
                        ['case', ['has', 'overrideFillOpacity'], ['get', 'overrideFillOpacity'], 1]
                    ]
                ]
            }
        },
        {
            id: 'WSRoomAllClear',
            type: 'fill',
            metadata: { 'mapbox:group': '73887ef967fbaf3abc8fac4a220398e6' },
            source: 'composite',
            'source-layer': 'SF36Rooms',
            layout: {},
            paint: { 'fill-color': 'hsla(0, 0%, 0%, 0)' }
        },
        {
            id: 'WSRoomFillWePT',
            type: 'fill',
            metadata: { 'mapbox:group': '73887ef967fbaf3abc8fac4a220398e6' },
            source: 'mapbox://telkowework.ck18abaqr1uu82npk29n6gntf-7pn21',
            'source-layer': 'SF36Rooms_5',
            filter: ['match', ['get', 'programTypeLabel'], ['WE'], true, false],
            layout: {},
            paint: {
                'fill-color': [
                    'case',
                    ['has', 'isSelected'],
                    '#ffcc00',
                    ['has', 'isHovered'],
                    'hsl(48, 100%, 85%)',
                    '#FFF5CC'
                ]
            }
        },
        {
            id: 'WSRoomFillCirculatePT',
            type: 'fill',
            metadata: { 'mapbox:group': '73887ef967fbaf3abc8fac4a220398e6' },
            source: 'mapbox://telkowework.ck18abaqr1uu82npk29n6gntf-7pn21',
            'source-layer': 'SF36Rooms_5',
            filter: [
                'all',
                ['match', ['get', 'programTypeLabel'], ['CIRCULATE'], true, false],
                ['match', ['get', 'spaceTypeLabel'], ['HALLWAY'], false, true]
            ],
            layout: {},
            paint: {
                'fill-color': [
                    'case',
                    ['has', 'isSelected'],
                    'hsl(0, 0%, 60%)',
                    ['has', 'isHovered'],
                    'hsl(0, 0%, 95%)',
                    'hsl(0, 0%, 100%)'
                ]
            }
        },
        {
            id: 'WSRoomFillUnknownPT',
            type: 'fill',
            metadata: { 'mapbox:group': '73887ef967fbaf3abc8fac4a220398e6' },
            source: 'mapbox://telkowework.ck18abaqr1uu82npk29n6gntf-7pn21',
            'source-layer': 'SF36Rooms_5',
            filter: ['match', ['get', 'programTypeLabel'], ['UNKNOWN'], true, false],
            layout: {},
            paint: {
                'fill-color': [
                    'case',
                    ['has', 'isSelected'],
                    'hsl(0, 0%, 60%)',
                    ['has', 'isHovered'],
                    'hsl(0, 0%, 95%)',
                    'hsl(0, 0%, 100%)'
                ]
            }
        },
        {
            id: 'WSRoomFillCirculateHallwayPT',
            type: 'fill',
            metadata: { 'mapbox:group': '73887ef967fbaf3abc8fac4a220398e6' },
            source: 'mapbox://telkowework.ck18abaqr1uu82npk29n6gntf-7pn21',
            'source-layer': 'SF36Rooms_5',
            filter: [
                'all',
                ['match', ['get', 'programTypeLabel'], ['CIRCULATE'], true, false],
                ['match', ['get', 'spaceTypeLabel'], ['HALLWAY'], true, false]
            ],
            layout: {},
            paint: {
                'fill-color': [
                    'case',
                    ['has', 'isSelected'],
                    'hsl(240, 2%, 52%)',
                    ['has', 'isHovered'],
                    'hsl(240, 2%, 87%)',
                    '#eaeaeb'
                ]
            }
        },
        {
            id: 'WSRoomFillWorkPT',
            type: 'fill',
            metadata: { 'mapbox:group': '73887ef967fbaf3abc8fac4a220398e6' },
            source: 'mapbox://telkowework.ck18abaqr1uu82npk29n6gntf-7pn21',
            'source-layer': 'SF36Rooms_5',
            filter: ['match', ['get', 'programTypeLabel'], ['WORK'], true, false],
            layout: {},
            paint: {
                'fill-color': [
                    'case',
                    ['has', 'isSelected'],
                    '#007FFF',
                    ['has', 'isHovered'],
                    'hsl(210, 100%, 90%)',
                    '#E5F2FF'
                ]
            }
        },
        {
            id: 'WSRoomFillMeetPT',
            type: 'fill',
            metadata: { 'mapbox:group': '73887ef967fbaf3abc8fac4a220398e6' },
            source: 'mapbox://telkowework.ck18abaqr1uu82npk29n6gntf-7pn21',
            'source-layer': 'SF36Rooms_5',
            filter: ['match', ['get', 'programTypeLabel'], ['MEET'], true, false],
            layout: {},
            paint: {
                'fill-color': [
                    'case',
                    ['has', 'isSelected'],
                    '#14B83E',
                    ['has', 'isHovered'],
                    'hsl(134, 84%, 90%)',
                    '#E8FDED'
                ]
            }
        },
        {
            id: 'WSRoomFillOperatePT',
            type: 'fill',
            metadata: { 'mapbox:group': '73887ef967fbaf3abc8fac4a220398e6' },
            source: 'mapbox://telkowework.ck18abaqr1uu82npk29n6gntf-7pn21',
            'source-layer': 'SF36Rooms_5',
            filter: ['match', ['get', 'programTypeLabel'], ['OPERATE'], true, false],
            layout: {},
            paint: {
                'fill-color': [
                    'case',
                    ['has', 'isSelected'],
                    'hsl(0, 0%, 40%)',
                    ['has', 'isHovered'],
                    'hsl(0, 0%, 75%)',
                    'hsl(0, 0%, 80%)'
                ]
            }
        },
        {
            id: 'WSRoomFillWashPT',
            type: 'fill',
            metadata: { 'mapbox:group': '73887ef967fbaf3abc8fac4a220398e6' },
            source: 'mapbox://telkowework.ck18abaqr1uu82npk29n6gntf-7pn21',
            'source-layer': 'SF36Rooms_5',
            filter: ['match', ['get', 'programTypeLabel'], ['WASH'], true, false],
            layout: {},
            paint: {
                'fill-color': [
                    'case',
                    ['has', 'isSelected'],
                    'hsl(240, 2%, 52%)',
                    ['has', 'isHovered'],
                    'hsl(240, 2%, 87%)',
                    'hsl(240, 2%, 92%)'
                ]
            }
        },
        {
            id: 'WSRoomPatternWePT',
            type: 'fill',
            metadata: { 'mapbox:group': '5a748c1569bb89783d136cc6bd83d613' },
            source: 'composite',
            'source-layer': 'SF36Rooms',
            filter: ['match', ['get', 'programTypeLabel'], ['WE'], true, false],
            layout: {},
            paint: {
                'fill-pattern': ['match', ['get', 'space_type'], ['LOUNGE'], 'Pattern_Vuitton', '']
            }
        },
        {
            id: 'WSRoomPatternUnknownPT',
            type: 'fill',
            metadata: { 'mapbox:group': '5a748c1569bb89783d136cc6bd83d613' },
            source: 'composite',
            'source-layer': 'SF36Rooms',
            filter: ['match', ['get', 'programTypeLabel'], ['UNKNOWN'], true, false],
            layout: { visibility: 'none' },
            paint: {
                'fill-pattern': 'question',
                'fill-color': 'hsl(0, 0%, 0%)'
            }
        },
        {
            id: 'WSRoomPatternWashPT',
            type: 'fill',
            metadata: { 'mapbox:group': '5a748c1569bb89783d136cc6bd83d613' },
            source: 'composite',
            'source-layer': 'SF36Rooms',
            filter: ['match', ['get', 'programTypeLabel'], ['WASH'], true, false],
            layout: {},
            paint: { 'fill-pattern': 'Pattern_Tiled_Room' }
        },
        {
            id: 'WSRoomOverrideFill',
            type: 'fill',
            source: 'mapbox://telkowework.ck18abaqr1uu82npk29n6gntf-7ol5j',
            'source-layer': 'SF36Rooms_4',
            filter: ['has', 'overrideFillColor'],
            layout: {},
            paint: {
                'fill-color': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['get', 'overrideSelectedFillColor'],
                        ['get', 'overrideHoverFillColor']
                    ],
                    ['case', ['has', 'isSelected'], ['get', 'overrideSelectedFillColor'], ['get', 'overrideFillColor']]
                ],
                'fill-opacity': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['get', 'overrideSelectedFillOpacity'],
                        ['get', 'overrideHoverFillOpacity']
                    ],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['get', 'overrideSelectedFillOpacity'],
                        ['get', 'overrideFillOpacity']
                    ]
                ]
            }
        },
        {
            id: 'WSRoomsOutline',
            type: 'line',
            source: 'composite',
            'source-layer': 'SF36Rooms',
            layout: {},
            paint: {
                'line-color': '#aaaaaa',
                'line-width': ['case', ['has', 'isSelected'], 2, 1]
            }
        },
        {
            id: 'WSRoomOverrideOutline',
            type: 'line',
            source: 'mapbox://telkowework.ck18abaqr1uu82npk29n6gntf-7ol5j',
            'source-layer': 'SF36Rooms_4',
            filter: ['has', 'overrideBorderColor'],
            layout: { 'line-cap': 'round', 'line-join': 'round' },
            paint: {
                'line-color': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['get', 'overrideSelectedBorderColor'],
                        ['get', 'overrideHoverBorderColor']
                    ],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['get', 'overrideSelectedBorderColor'],
                        ['get', 'overrideBorderColor']
                    ]
                ],
                'line-width': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['get', 'overrideSelectedBorderWidth'],
                        ['get', 'overrideHoverBorderWidth']
                    ],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['get', 'overrideSelectedBorderWidth'],
                        ['get', 'overrideBorderWidth']
                    ]
                ],
                'line-opacity': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['get', 'overrideSelectedBorderOpacity'],
                        ['get', 'overrideHoverBorderOpacity']
                    ],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['get', 'overrideSelectedBorderOpacity'],
                        ['get', 'overrideBorderOpacity']
                    ]
                ]
            }
        },
        {
            id: 'WSWallFill',
            type: 'fill',
            source: 'composite',
            'source-layer': 'SF36Doors',
            layout: {},
            paint: {
                'fill-color': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['case', ['has', 'overrideSelectedFillColor'], ['get', 'overrideSelectedFillColor'], '#dddddd'],
                        ['case', ['has', 'overrideHoverFillColor'], ['get', 'overrideHoverFillColor'], '#dddddd']
                    ],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['case', ['has', 'overrideSelectedFillColor'], ['get', 'overrideSelectedFillColor'], '#dddddd'],
                        ['case', ['has', 'overrideFillColor'], ['get', 'overrideFillColor'], '#dddddd']
                    ]
                ],
                'fill-opacity': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['case', ['has', 'overrideSelectedFillOpacity'], ['get', 'overrideSelectedFillOpacity'], 1],
                        ['case', ['has', 'overrideHoverFillOpacity'], ['get', 'overrideHoverFillOpacity'], 1]
                    ],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['case', ['has', 'overrideSelectedFillOpacity'], ['get', 'overrideSelectedFillOpacity'], 1],
                        ['case', ['has', 'overrideFillOpacity'], ['get', 'overrideFillOpacity'], 1]
                    ]
                ],
                'fill-outline-color': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['case', ['has', 'overrideSelectedFillColor'], ['get', 'overrideSelectedFillColor'], '#dddddd'],
                        ['case', ['has', 'overrideHoverFillColor'], ['get', 'overrideHoverFillColor'], '#dddddd']
                    ],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['case', ['has', 'overrideSelectedFillColor'], ['get', 'overrideSelectedFillColor'], '#dddddd'],
                        ['case', ['has', 'overrideFillColor'], ['get', 'overrideFillColor'], '#dddddd']
                    ]
                ]
            }
        },
        {
            id: 'WSWallOutline',
            type: 'line',
            source: 'composite',
            'source-layer': 'SF36Doors',
            layout: {},
            paint: {
                'line-color': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    [
                        'case',
                        ['has', 'isSelected'],
                        [
                            'case',
                            ['has', 'overrideSelectedBorderColor'],
                            ['get', 'overrideSelectedBorderColor'],
                            '#aaaaaa'
                        ],
                        ['case', ['has', 'overrideHoverBorderColor'], ['get', 'overrideHoverBorderColor'], '#aaaaaa']
                    ],
                    [
                        'case',
                        ['has', 'isSelected'],
                        [
                            'case',
                            ['has', 'overrideSelectedBorderColor'],
                            ['get', 'overrideSelectedBorderColor'],
                            '#aaaaaa'
                        ],
                        ['case', ['has', 'overrideBorderColor'], ['get', 'overrideBorderColor'], '#aaaaaa']
                    ]
                ],
                'line-width': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['case', ['has', 'overrideSelectedBorderWidth'], ['get', 'overrideSelectedBorderWidth'], 0],
                        ['case', ['has', 'overrideHoverBorderWidth'], ['get', 'overrideHoverBorderWidth'], 0]
                    ],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['case', ['has', 'overrideSelectedBorderWidth'], ['get', 'overrideSelectedBorderWidth'], 0],
                        ['case', ['has', 'overrideBorderWidth'], ['get', 'overrideBorderWidth'], 0]
                    ]
                ],
                'line-opacity': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['case', ['has', 'overrideSelectedBorderOpacity'], ['get', 'overrideSelectedBorderOpacity'], 1],
                        ['case', ['has', 'overrideHoverBorderOpacity'], ['get', 'overrideHoverBorderOpacity'], 1]
                    ],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['case', ['has', 'overrideSelectedBorderOpacity'], ['get', 'overrideSelectedBorderOpacity'], 1],
                        ['case', ['has', 'overrideBorderOpacity'], ['get', 'overrideBorderOpacity'], 1]
                    ]
                ]
            }
        },
        {
            id: 'WSObjectChairOutline',
            type: 'line',
            source: 'composite',
            'source-layer': 'SF36Objects',
            minzoom: 18,
            filter: ['match', ['get', 'objectType'], ['CHAIR'], true, false],
            layout: {},
            paint: {
                'line-width': ['case', ['has', 'isSelected'], 1, 0.3],
                'line-color': '#aaaaaa'
            }
        },
        {
            id: 'WSObjectDeskFills',
            type: 'fill',
            source: 'composite',
            'source-layer': 'SF36Objects',
            minzoom: 18,
            filter: ['match', ['get', 'objectType'], ['TABLE'], true, false],
            layout: {},
            paint: {
                'fill-color': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    0,
                    ['case', ['has', 'isSelected'], 'hsl(0, 0%, 60%)', 'hsl(0, 0%, 100%)'],
                    22,
                    ['case', ['has', 'isSelected'], 'hsl(0, 0%, 60%)', 'hsl(0, 0%, 100%)']
                ]
            }
        },
        {
            id: 'WSObjectDeskOutlines',
            type: 'line',
            source: 'composite',
            'source-layer': 'SF36Objects',
            minzoom: 18,
            filter: ['match', ['get', 'objectType'], ['TABLE'], true, false],
            layout: {},
            paint: {
                'line-width': ['case', ['has', 'isSelected'], 1, 0.3],
                'line-color': '#aaaaaa'
            }
        },
        {
            id: 'WSDoorFill',
            type: 'fill',
            source: 'composite',
            'source-layer': 'SF36Doors',
            paint: {
                'fill-color': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['case', ['has', 'overrideSelectedFillColor'], ['get', 'overrideSelectedFillColor'], '#666666'],
                        ['case', ['has', 'overrideHoverFillColor'], ['get', 'overrideHoverFillColor'], '#666666']
                    ],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['case', ['has', 'overrideSelectedFillColor'], ['get', 'overrideSelectedFillColor'], '#666666'],
                        ['case', ['has', 'overrideFillColor'], ['get', 'overrideFillColor'], '#666666']
                    ]
                ],
                'fill-outline-color': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['case', ['has', 'overrideSelectedFillColor'], ['get', 'overrideSelectedFillColor'], '#666666'],
                        ['case', ['has', 'overrideHoverFillColor'], ['get', 'overrideHoverFillColor'], '#666666']
                    ],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['case', ['has', 'overrideSelectedFillColor'], ['get', 'overrideSelectedFillColor'], '#666666'],
                        ['case', ['has', 'overrideFillColor'], ['get', 'overrideFillColor'], '#666666']
                    ]
                ],
                'fill-opacity': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['case', ['has', 'overrideSelectedFillOpacity'], ['get', 'overrideSelectedFillOpacity'], 1],
                        ['case', ['has', 'overrideHoverFillOpacity'], ['get', 'overrideHoverFillOpacity'], 1]
                    ],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['case', ['has', 'overrideSelectedFillOpacity'], ['get', 'overrideSelectedFillOpacity'], 1],
                        ['case', ['has', 'overrideFillOpacity'], ['get', 'overrideFillOpacity'], 1]
                    ]
                ]
            }
        },
        {
            id: 'WSDoorOutline',
            type: 'line',
            source: 'composite',
            'source-layer': 'SF36Doors',
            layout: {},
            paint: {
                'line-color': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    [
                        'case',
                        ['has', 'isSelected'],
                        [
                            'case',
                            ['has', 'overrideSelectedBorderColor'],
                            ['get', 'overrideSelectedBorderColor'],
                            '#aaaaaa'
                        ],
                        ['case', ['has', 'overrideHoverBorderColor'], ['get', 'overrideHoverBorderColor'], '#aaaaaa']
                    ],
                    [
                        'case',
                        ['has', 'isSelected'],
                        [
                            'case',
                            ['has', 'overrideSelectedBorderColor'],
                            ['get', 'overrideSelectedBorderColor'],
                            '#aaaaaa'
                        ],
                        ['case', ['has', 'overrideBorderColor'], ['get', 'overrideBorderColor'], '#aaaaaa']
                    ]
                ],
                'line-width': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['case', ['has', 'overrideSelectedBorderWidth'], ['get', 'overrideSelectedBorderWidth'], 0],
                        ['case', ['has', 'overrideHoverBorderWidth'], ['get', 'overrideHoverBorderWidth'], 0]
                    ],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['case', ['has', 'overrideSelectedBorderWidth'], ['get', 'overrideSelectedBorderWidth'], 0],
                        ['case', ['has', 'overrideBorderWidth'], ['get', 'overrideBorderWidth'], 0]
                    ]
                ],
                'line-opacity': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['case', ['has', 'overrideSelectedBorderOpacity'], ['get', 'overrideSelectedBorderOpacity'], 1],
                        ['case', ['has', 'overrideHoverBorderOpacity'], ['get', 'overrideHoverBorderOpacity'], 1]
                    ],
                    [
                        'case',
                        ['has', 'isSelected'],
                        ['case', ['has', 'overrideSelectedBorderOpacity'], ['get', 'overrideSelectedBorderOpacity'], 1],
                        ['case', ['has', 'overrideBorderOpacity'], ['get', 'overrideBorderOpacity'], 1]
                    ]
                ]
            }
        },
        {
            id: 'WSAnnotationPolygonsFillUnderLabels',
            type: 'fill',
            source: 'mapbox://telkowework.ckk4ggu341mhq20lcaxsmr21i-9rpms',
            'source-layer': 'Annotations5',
            filter: ['has', 'underLabels'],
            paint: {
                'fill-color': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    ['case', ['has', 'isSelected'], ['get', 'selectedFillColor'], ['get', 'hoverFillColor']],
                    ['case', ['has', 'isSelected'], ['get', 'selectedFillColor'], ['get', 'fillColor']]
                ],
                'fill-opacity': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    ['case', ['has', 'isSelected'], ['get', 'selectedFillOpacity'], ['get', 'hoverFillOpacity']],
                    ['case', ['has', 'isSelected'], ['get', 'selectedFillOpacity'], ['get', 'fillOpacity']]
                ],
                'fill-outline-color': 'hsla(0, 0%, 0%, 0)'
            }
        },
        {
            id: 'WSAnnotationPolygonOutlinesUnderLabels',
            type: 'line',
            source: 'mapbox://telkowework.ckk4ggu341mhq20lcaxsmr21i-9rpms',
            'source-layer': 'Annotations5',
            filter: ['has', 'underLabels'],
            layout: { 'line-cap': 'round', 'line-join': 'round' },
            paint: {
                'line-color': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    ['case', ['has', 'isSelected'], ['get', 'selectedBorderColor'], ['get', 'hoverBorderColor']],
                    ['case', ['has', 'isSelected'], ['get', 'selectedBorderColor'], ['get', 'borderColor']]
                ],
                'line-opacity': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    ['case', ['has', 'isSelected'], ['get', 'selectedBorderOpacity'], ['get', 'hoverBorderOpacity']],
                    ['case', ['has', 'isSelected'], ['get', 'selectedBorderOpacity'], ['get', 'borderOpacity']]
                ],
                'line-width': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    ['case', ['has', 'isSelected'], ['get', 'selectedBorderWidth'], ['get', 'hoverBorderWidth']],
                    ['case', ['has', 'isSelected'], ['get', 'selectedBorderWidth'], ['get', 'borderWidth']]
                ]
            }
        },
        {
            id: 'WSObjectTitle',
            type: 'symbol',
            source: 'composite',
            'source-layer': 'SF36Rooms',
            minzoom: 20.5,
            layout: {
                'text-field': ['get', 'roomNumber'],
                'text-font': ['Apercu WeWork Mono Bold'],
                'text-size': ['interpolate', ['exponential', 1.96], ['zoom'], 0, 0, 21, 16],
                'text-offset': [0, -1],
                'text-line-height': 1,
                'text-variable-anchor': [
                    'center',
                    'bottom',
                    'top',
                    'left',
                    'right',
                    'bottom-right',
                    'bottom-left',
                    'top-left',
                    'top-right'
                ],
                'text-radial-offset': 2,
                visibility: 'none'
            },
            paint: {}
        },
        {
            id: 'WSRoomSubtitle',
            type: 'symbol',
            source: 'composite',
            'source-layer': 'SF36Rooms',
            minzoom: 20.5,
            layout: {
                'text-font': ['Apercu WeWork Mono Light'],
                'text-size': ['interpolate', ['exponential', 1.96], ['zoom'], 0, 0, 21, 12],
                'text-offset': [0, 1.2],
                'text-field': ['get', 'spaceTypeLabel'],
                'text-max-width': 5,
                'text-variable-anchor': [
                    'center',
                    'bottom',
                    'top',
                    'left',
                    'right',
                    'bottom-right',
                    'bottom-left',
                    'top-left',
                    'top-right'
                ],
                'text-radial-offset': 2
            },
            paint: {}
        },
        {
            id: 'WSRoomTitle',
            type: 'symbol',
            source: 'composite',
            'source-layer': 'SF36Rooms',
            minzoom: 20.5,
            layout: {
                'text-field': ['get', 'roomNumber'],
                'text-font': ['Apercu WeWork Mono Bold'],
                'text-size': ['interpolate', ['exponential', 1.96], ['zoom'], 0, 0, 21, 16],
                'text-offset': [0, -1],
                'text-line-height': 1,
                'text-variable-anchor': [
                    'center',
                    'bottom',
                    'top',
                    'left',
                    'right',
                    'bottom-right',
                    'bottom-left',
                    'top-left',
                    'top-right'
                ],
                'text-radial-offset': 2
            },
            paint: {}
        },
        {
            id: 'WSPortalIcons',
            type: 'symbol',
            source: 'composite',
            'source-layer': 'SF36Portals2',
            minzoom: 18,
            layout: {
                'icon-offset': ['interpolate', ['linear'], ['zoom'], 0, ['literal', [0, 0]], 22, ['literal', [0, 40]]],
                'icon-rotate': ['get', 'headingOfAccess'],
                'icon-size': ['interpolate', ['exponential', 1.5], ['zoom'], 0, 0, 22, 1],
                'icon-rotation-alignment': 'map',
                'icon-image': [
                    'case',
                    ['has', 'isSelected'],
                    'reader-selected-padded',
                    ['has', 'overrideFillColor'],
                    'reader-light-padded',
                    'reader-padded'
                ],
                'icon-allow-overlap': true,
                'icon-padding': 0
            },
            paint: {}
        },
        {
            id: 'WSPortalDebug',
            type: 'circle',
            source: 'composite',
            'source-layer': 'SF36Portals2',
            layout: { visibility: 'none' },
            paint: { 'circle-color': 'hsl(119, 100%, 47%)', 'circle-radius': 2 }
        },
        {
            id: 'WSAnnotationPolygonsFill',
            type: 'fill',
            source: 'mapbox://telkowework.ckk4ggu341mhq20lcaxsmr21i-9rpms',
            'source-layer': 'Annotations5',
            filter: ['all', ['has', 'polygon'], ['!=', ['get', 'underLabels'], true]],
            paint: {
                'fill-color': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    ['case', ['has', 'isSelected'], ['get', 'selectedFillColor'], ['get', 'hoverFillColor']],
                    ['case', ['has', 'isSelected'], ['get', 'selectedFillColor'], ['get', 'fillColor']]
                ],
                'fill-opacity': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    ['case', ['has', 'isSelected'], ['get', 'selectedFillOpacity'], ['get', 'hoverFillOpacity']],
                    ['case', ['has', 'isSelected'], ['get', 'selectedFillOpacity'], ['get', 'fillOpacity']]
                ],
                'fill-outline-color': 'hsla(0, 0%, 0%, 0)'
            }
        },
        {
            id: 'WSAnnotationPolygonOutlines',
            type: 'line',
            source: 'mapbox://telkowework.ckk4ggu341mhq20lcaxsmr21i-9rpms',
            'source-layer': 'Annotations5',
            filter: ['all', ['has', 'polygon'], ['!=', ['get', 'underLabels'], true]],
            layout: { 'line-cap': 'round', 'line-join': 'round' },
            paint: {
                'line-color': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    ['case', ['has', 'isSelected'], ['get', 'selectedBorderColor'], ['get', 'hoverBorderColor']],
                    ['case', ['has', 'isSelected'], ['get', 'selectedBorderColor'], ['get', 'borderColor']]
                ],
                'line-opacity': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    ['case', ['has', 'isSelected'], ['get', 'selectedBorderOpacity'], ['get', 'hoverBorderOpacity']],
                    ['case', ['has', 'isSelected'], ['get', 'selectedBorderOpacity'], ['get', 'borderOpacity']]
                ],
                'line-width': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    ['case', ['has', 'isSelected'], ['get', 'selectedBorderWidth'], ['get', 'hoverBorderWidth']],
                    ['case', ['has', 'isSelected'], ['get', 'selectedBorderWidth'], ['get', 'borderWidth']]
                ]
            }
        },
        {
            id: 'WSAnnotationLine',
            type: 'line',
            source: 'mapbox://telkowework.ckk4ggu341mhq20lcaxsmr21i-9rpms',
            'source-layer': 'Annotations5',
            filter: ['has', 'line'],
            layout: { 'line-join': 'round', 'line-cap': 'round' },
            paint: {
                'line-color': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    ['case', ['has', 'isSelected'], ['get', 'selectedBorderColor'], ['get', 'hoverBorderColor']],
                    ['case', ['has', 'isSelected'], ['get', 'selectedBorderColor'], ['get', 'borderColor']]
                ],
                'line-opacity': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    ['case', ['has', 'isSelected'], ['get', 'selectedBorderOpacity'], ['get', 'hoverBorderOpacity']],
                    ['case', ['has', 'isSelected'], ['get', 'selectedBorderOpacity'], ['get', 'borderOpacity']]
                ],
                'line-width': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    ['case', ['has', 'isSelected'], ['get', 'selectedBorderWidth'], ['get', 'hoverBorderWidth']],
                    ['case', ['has', 'isSelected'], ['get', 'selectedBorderWidth'], ['get', 'borderWidth']]
                ]
            }
        },
        {
            id: 'WSAnnotationImages',
            type: 'symbol',
            source: 'composite',
            'source-layer': 'SF36Portals2',
            minzoom: 18,
            filter: ['has', 'image'],
            layout: {
                'icon-image': ['case', ['has', 'isSelected'], ['get', 'selectedImage'], ['get', 'image']],
                'icon-size': ['interpolate', ['exponential', 1.5], ['zoom'], 0, 0, 22, 1],
                'icon-allow-overlap': true
            },
            paint: {}
        },
        {
            id: 'WSAnnotationLabels',
            type: 'symbol',
            source: 'mapbox://telkowework.ckk4ggu341mhq20lcaxsmr21i-1r41w',
            'source-layer': 'Annotations7',
            filter: ['has', 'label'],
            layout: {
                'text-field': ['get', 'label'],
                'text-font': ['Apercu WeWork Mono Bold'],
                'text-size': ['interpolate', ['exponential', 1.96], ['zoom'], 0, 0, 21, 14],
                'text-line-height': 1,
                'text-variable-anchor': [
                    'center',
                    'bottom',
                    'top',
                    'right',
                    'left',
                    'bottom-right',
                    'bottom-left',
                    'top-left',
                    'top-right'
                ],
                'text-radial-offset': 1
            },
            paint: {}
        }
    ],
    created: '2021-05-24T20:17:34.672Z',
    modified: '2021-09-01T20:22:39.300Z',
    id: 'ckp31wxbv6z9017ozgnis42fq',
    owner: 'telkowework',
    visibility: 'private',
    draft: true
}
