import { FPFloor, FPObject, FPPortal, FPRoom, FPWall, FPDoor } from '../../services/FloorPlanData.public.interfaces'
import { Feature, Point, Polygon } from 'geojson'
import { FloorPlanFeatureProperties, Projection } from './FloorPlanToGeoJsonConverter.interfaces'
import { PolygonAnnotationStyle } from '../FloorPlan.public.interfaces'

const enhanceFeature = (
    feature: Feature<Polygon | Point, FloorPlanFeatureProperties>,
    customStyle: PolygonAnnotationStyle
) => {
    if (customStyle) {
        if (customStyle.style?.fill?.color) feature.properties['overrideFillColor'] = customStyle.style.fill.color
        if (customStyle.style?.fill?.opacity) feature.properties['overrideFillOpacity'] = customStyle.style.fill.opacity

        if (customStyle.style?.line?.color) feature.properties['overrideBorderColor'] = customStyle.style.line.color
        if (customStyle.style?.line?.width) feature.properties['overrideBorderWidth'] = customStyle.style.line.width
        if (customStyle.style?.line?.opacity)
            feature.properties['overrideBorderOpacity'] = customStyle.style.line.opacity

        // Hover
        if (customStyle.hoverStyle?.fill?.color)
            feature.properties['overrideHoverFillColor'] = customStyle.hoverStyle.fill.color
        if (customStyle.hoverStyle?.fill?.opacity)
            feature.properties['overrideHoverFillOpacity'] = customStyle.hoverStyle.fill.opacity

        if (customStyle.hoverStyle?.line?.color)
            feature.properties['overrideHoverBorderColor'] = customStyle.hoverStyle.line.color
        if (customStyle.hoverStyle?.line?.width)
            feature.properties['overrideHoverBorderWidth'] = customStyle.hoverStyle.line.width
        if (customStyle.hoverStyle?.line?.opacity)
            feature.properties['overrideHoverBorderOpacity'] = customStyle.hoverStyle.line.opacity

        // Selected
        if (customStyle.selectedStyle?.fill?.color)
            feature.properties['overrideSelectedFillColor'] = customStyle.selectedStyle.fill.color
        if (customStyle.selectedStyle?.fill?.opacity)
            feature.properties['overrideSelectedFillOpacity'] = customStyle.selectedStyle.fill.opacity

        if (customStyle.selectedStyle?.line?.color)
            feature.properties['overrideSelectedBorderColor'] = customStyle.selectedStyle.line.color
        if (customStyle.selectedStyle?.line?.width)
            feature.properties['overrideSelectedBorderWidth'] = customStyle.selectedStyle.line.width
        if (customStyle.selectedStyle?.line?.opacity)
            feature.properties['overrideSelectedBorderOpacity'] = customStyle.selectedStyle.line.opacity

        // feature.properties['isSelected'] = true
        // feature.properties['isHovered'] = true // - it works for walls and doors
    }
}
export class FloorPlanToGeoJsonConverter {
    static convertFloor(
        item: FPFloor,
        projection: Projection,
        customStyleProvider?: (room: FPFloor) => PolygonAnnotationStyle | null
    ): Feature<Polygon, FloorPlanFeatureProperties> {
        const feature: Feature<Polygon, FloorPlanFeatureProperties> = {
            id: item.uuid,
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [
                    item.projectedBoundariesInFeet?.outerBoundary?.pointsList?.map((point) =>
                        projection.unProject(point)
                    ) || []
                ].concat(
                    item.projectedBoundariesInFeet?.innerVoidBoundariesList?.map(
                        (boundary) => boundary.pointsList.map((point) => projection.unProject(point)) || []
                    ) || []
                )
            },
            properties: {
                id: item.uuid,
                label: item.label || ''
            }
        }

        const customStyle = customStyleProvider ? customStyleProvider(item) : null
        if (customStyle) enhanceFeature(feature, customStyle)

        return feature
    }

    static convertRooms(
        items: FPRoom[],
        projection: Projection,
        customStyleProvider?: (room: FPRoom) => PolygonAnnotationStyle | null
    ): Feature<Polygon, FloorPlanFeatureProperties>[] {
        return items.map((item) => {
            const feature: Feature<Polygon, FloorPlanFeatureProperties> = {
                id: item.uuid,
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: [
                        item?.projectedBoundariesInFeet?.outerBoundary?.pointsList?.map((point) =>
                            projection.unProject(point)
                        ) || []
                    ].concat(
                        item?.projectedBoundariesInFeet?.innerVoidBoundariesList?.map(
                            (boundary) => boundary.pointsList.map((point) => projection.unProject(point)) || []
                        ) || []
                    )
                },
                properties: {
                    id: item.uuid,
                    programTypeLabel: item?.programType?.toUpperCase(),
                    roomNumber: item?.roomNumber,
                    spaceTypeLabel: item?.spaceType?.toUpperCase()
                }
            }
            const customStyle = customStyleProvider ? customStyleProvider(item) : null
            if (customStyle) enhanceFeature(feature, customStyle)

            return feature
        })
    }

    static convertWalls(
        items: FPWall[],
        projection: Projection,
        customStyleProvider?: (room: FPWall) => PolygonAnnotationStyle | null
    ): Feature<Polygon, FloorPlanFeatureProperties>[] {
        return items.map((item) => {
            const feature: Feature<Polygon, FloorPlanFeatureProperties> = {
                id: item.uuid,
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: [
                        item?.planBoundariesInFeet?.outerBoundary?.pointsList?.map((point) =>
                            projection.unProject(point)
                        ) || []
                    ].concat(
                        item?.planBoundariesInFeet?.innerVoidBoundariesList?.map(
                            (boundary) => boundary.pointsList.map((point) => projection.unProject(point)) || []
                        ) || []
                    )
                },
                properties: {
                    id: item.uuid,
                    transparency: item.transparency || 0
                }
            }

            const customStyle = customStyleProvider ? customStyleProvider(item) : null
            if (customStyle) enhanceFeature(feature, customStyle)

            return feature
        })
    }

    static convertObjects(items: FPObject[], projection: Projection): Feature<Polygon, FloorPlanFeatureProperties>[] {
        return items.map((item) => ({
            id: item.uuid,
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [
                    item?.projectedBoundariesInFeet?.outerBoundary?.pointsList?.map((point) =>
                        projection.unProject(point)
                    ) || []
                ].concat(
                    item?.projectedBoundariesInFeet?.innerVoidBoundariesList?.map(
                        (boundary) => boundary.pointsList.map((point) => projection.unProject(point)) || []
                    ) || []
                )
            },
            properties: {
                id: item.uuid,
                objectType: item.objectType || ''
            }
        }))
    }

    static convertDoors(
        items: FPDoor[],
        projection: Projection,
        customStyleProvider?: (room: FPDoor) => PolygonAnnotationStyle | null
    ): Feature<Polygon, FloorPlanFeatureProperties>[] {
        return items.map((item) => {
            const feature: Feature<Polygon, FloorPlanFeatureProperties> = {
                id: item.uuid,
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: [
                        item?.projectedBoundariesInFeet?.outerBoundary?.pointsList?.map((point) =>
                            projection.unProject(point)
                        ) || []
                    ].concat(
                        item?.projectedBoundariesInFeet?.innerVoidBoundariesList?.map(
                            (boundary) => boundary.pointsList.map((point) => projection.unProject(point)) || []
                        ) || []
                    )
                },
                properties: {
                    id: item.uuid
                }
            }

            const customStyle = customStyleProvider ? customStyleProvider(item) : null
            if (customStyle) enhanceFeature(feature, customStyle)

            return feature
        })
    }

    static convertPortals(items: FPPortal[], projection: Projection): Feature<Point, FloorPlanFeatureProperties>[] {
        return items.map((item): Feature<Point, FloorPlanFeatureProperties> => {
            const feature: Feature<Point, FloorPlanFeatureProperties> = {
                id: item.uuid,
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: projection.unProject(item?.planLocationInFeet || { x: 0, y: 0 }) || []
                },
                properties: {
                    id: item.uuid,
                    headingOfAccess: item.accessDirection || 0
                }
            }

            return feature
        })
    }
}
