import { useEffect, useRef } from 'react'
import { FloorPlanProps } from '../index'
import { FloorPlan, FloorPlanBaseMapStyle } from '@wework/floor-plan-sdk'

/**
 * A react component providing an interactive floor plan.
 * @param config - Required configuration parameters
 * @param floorId - An SRS floor id. The map will load and display this floor with its child rooms, walls and doors.
 * @param selectedIds - An array of base map and annotation feature ids to render in a 'selected' style.
 * @param annotations - An array of polygon annotations to render above the base map.
 * @param camera - A camera object describing a new map bounding box. Pass an updated camera object here to update the map camera.
 * @param zoomToFeatureId - The id of a feature (floor, room, wall... etc) upon which to center and zoom the map.
 * @param zoomToPoint - A point upon which to center the map. If maxZoom level is not set, the map will zoom all the way in.
 * @param floorHoverEnabled - When enabled, floor will show a hover state.
 * @param roomHoverEnabled - When enabled, rooms will show a hover state.
 * @param wallHoverEnabled - When enabled, walls will show a hover state.
 * @param doorHoverEnabled - When enabled, doors will show a hover state.
 * @param allowsUserInteraction - Enables the "drag to pan" interaction. By default is true.
 * @param children - specify base map features.
 * @param download - trigger download 
 * @param degreesRotateTo - trigger rotation to degrees.
 * @param onClick - A callback for map click events.
 * @param onMouseMove - A callback for mouse move events.
 * @param shouldHoverAnnotationIds - A callback specifying which features should be shown as hovered.
 * Passing null or not specifying a callback function will result in a default/normal hover experience.
 * @param shouldDragAnnotationIds - A callback specifying which features should be dragged in the upcoming drag operation.
 * @param shouldRotateAnnotationIds - A callback specifying which features should be rotated in the upcoming rotate operation.
 * @param onAnnotationDrag - A callback for map annotation drag events.
 * @param onCameraChanged - A callback for map camera change events.
 * @param style - Allows SDK users to apply custom style to base map features (e.g. room fill colors).
 * The supplied function is called after map data is loaded and before it is rendered in the map.
 * Setting a new value to this property causes the map to redraw all base map features.
 */
export function ReactFloorPlan({
    config,
    mapStyle,
    floorId,
    selectedIds,
    annotations,
    camera,
    zoomToFeatureId,
    zoomToPoint,
    floorHoverEnabled = false,
    roomHoverEnabled = false,
    wallHoverEnabled = false,
    doorHoverEnabled = false,
    allowsUserInteraction = true,
    children,
    styles,
    download = { name: '' },
    degreesRotateTo = 0,
    onClick,
    onWillLoad,
    onLoad,
    onMouseMove,
    shouldHoverAnnotationIds,
    shouldDragAnnotationIds,
    shouldRotateAnnotationIds,
    onAnnotationDrag,
    onCameraChanged
}: FloorPlanProps) {
    const mapContainer = useRef<HTMLElement | string>('')
    const floorPlan = useRef<FloorPlan | null>(null)

    // init map on component load
    useEffect(() => {
        floorPlan.current = new FloorPlan(mapContainer.current, config)
    }, [])

    useEffect(() => {
        floorPlan.current!.mapStyle = mapStyle || FloorPlanBaseMapStyle.DEFAULT
    }, [mapStyle])

    useEffect(() => {
        if (children) {
            floorPlan.current!.children = children
        }
        floorPlan.current!.floorId = floorId
    }, [floorId, children])

    useEffect(() => {
        floorPlan.current!.selectedIds = selectedIds || []
    }, [selectedIds])

    useEffect(() => {
        floorPlan.current!.annotations = annotations || []
    }, [annotations])

    useEffect(() => {
        if (camera) floorPlan.current!.camera = camera
    }, [camera])

    useEffect(() => {
        floorPlan.current!.floorHoverEnabled = floorHoverEnabled || false
    }, [floorHoverEnabled])

    useEffect(() => {
        floorPlan.current!.roomHoverEnabled = roomHoverEnabled
    }, [roomHoverEnabled])

    useEffect(() => {
        floorPlan.current!.wallHoverEnabled = wallHoverEnabled
    }, [wallHoverEnabled])

    useEffect(() => {
        floorPlan.current!.doorHoverEnabled = doorHoverEnabled
    }, [doorHoverEnabled])

    useEffect(() => {
        floorPlan.current!.allowsUserInteraction = allowsUserInteraction
    }, [allowsUserInteraction])

    useEffect(() => {
        if (download && download.name) floorPlan.current!.download = download
    }, [download])

    useEffect(() => {
        if (degreesRotateTo || degreesRotateTo === 0) floorPlan.current!.degreesRotateTo = degreesRotateTo
    }, [degreesRotateTo])

    useEffect(() => {
        floorPlan.current!.onWillLoad = onWillLoad
    }, [onWillLoad])

    useEffect(() => {
        floorPlan.current!.onLoad = onLoad
    }, [onLoad])

    useEffect(() => {
        floorPlan.current!.onClick = onClick
    }, [onClick])

    useEffect(() => {
        floorPlan.current!.onMouseMove = onMouseMove
    }, [onMouseMove])

    useEffect(() => {
        floorPlan.current!.shouldHoverAnnotationIds = shouldHoverAnnotationIds
    }, [shouldHoverAnnotationIds])

    useEffect(() => {
        floorPlan.current!.shouldDragAnnotationIds = shouldDragAnnotationIds
    }, [shouldDragAnnotationIds])

    useEffect(() => {
        floorPlan.current!.shouldRotateAnnotationIds = shouldRotateAnnotationIds
    }, [shouldRotateAnnotationIds])

    useEffect(() => {
        floorPlan.current!.onAnnotationDrag = onAnnotationDrag
    }, [onAnnotationDrag])

    useEffect(() => {
        floorPlan.current!.onCameraChanged = onCameraChanged
    }, [onCameraChanged])

    useEffect(() => {
        // A fairly expensive function call. Triggers redraw of entire map.
        floorPlan.current!.onFloorCustomStyle = styles?.onFloor
    }, [styles?.onFloor])

    useEffect(() => {
        // A fairly expensive function call. Triggers redraw of entire map.
        floorPlan.current!.onRoomCustomStyle = styles?.onRoom
    }, [styles?.onRoom])

    useEffect(() => {
        // A fairly expensive function call. Triggers redraw of entire map.
        floorPlan.current!.onWallCustomStyle = styles?.onWall
    }, [styles?.onWall])

    useEffect(() => {
        // A fairly expensive function call. Triggers redraw of entire map.
        floorPlan.current!.onDoorCustomStyle = styles?.onDoor
    }, [styles?.onDoor])

    useEffect(() => {
        if (zoomToFeatureId)
            floorPlan.current!.zoomToFeatureId(
                zoomToFeatureId.id,
                zoomToFeatureId.animated,
                zoomToFeatureId.maxZoomLevel
            )
    }, [zoomToFeatureId])

    useEffect(() => {
        if (zoomToPoint)
            floorPlan.current!.zoomToPoint(zoomToPoint.point, zoomToPoint.animated, zoomToPoint.maxZoomLevel)
    }, [zoomToPoint])

    return <div ref={(el) => (mapContainer.current = el as HTMLElement)} className="floor-plan" />
}
