import { Feature, Geometry, LineString, Point, Polygon } from 'geojson'
import { FloorPlanFeatureProperties, Projection } from './FloorPlanToGeoJsonConverter.interfaces'
import { PolygonAnnotation, PointAnnotation, LineAnnotation } from '../FloorPlan.public.interfaces'
import { srsPointFromArray, WeSpaceProjection } from './WeSpaceProjection'
import { Annotation } from '../FloorPlan.public.interfaces'
import { fastHash } from '../snapping/helpers'

export function convertAnnotationsToGeoJSON(
    annotations: Array<PolygonAnnotation | PointAnnotation | LineAnnotation>,
    projection: Projection = new WeSpaceProjection()
): Feature<Geometry, FloorPlanFeatureProperties>[] {
    return annotations.map((a) => {
        if (isPointAnnotation(a as Annotation)) {
            return featureForPointAnnotation(a as PointAnnotation, projection)
        } else if (isLineAnnotation(a as Annotation)) {
            return featureForLineAnnotation(a as LineAnnotation, projection)
        } else {
            return featureForPolygonAnnotation(a as PolygonAnnotation, projection)
        }
    })
}

export function isPointAnnotation(annotation: Annotation): annotation is PointAnnotation {
    return annotation?.geometry?.type === 'Point'
}

export function isLineAnnotation(annotation: Annotation): annotation is LineAnnotation {
    return annotation?.geometry?.type === 'LineString'
}

export function isPolygonAnnotation(annotation: Annotation): annotation is PolygonAnnotation {
    return annotation?.geometry?.type === 'Polygon'
}

function featureForPointAnnotation(
    annotation: PointAnnotation,
    projection: Projection = new WeSpaceProjection()
): Feature<Point, FloorPlanFeatureProperties> {
    const feature: Feature<Point, FloorPlanFeatureProperties> = {
        id: annotation.id,
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: projection.unProject(srsPointFromArray(annotation.geometry.coordinates))
        },
        properties: {
            id: annotation.id,
            image: '',
            selectedImage: ''
        }
    }
    if (annotation.properties?.style?.icon) {
        feature.properties.image = fastHash(annotation.properties.style.icon).toString()
        feature.properties.selectedImage = fastHash(annotation.properties.style.icon).toString()
    }
    if (annotation.properties?.selectedStyle?.icon) {
        feature.properties.selectedImage = fastHash(annotation.properties.selectedStyle.icon).toString()
    }

    return feature
}

function featureForLineAnnotation(
    annotation: LineAnnotation,
    projection: Projection = new WeSpaceProjection()
): Feature<LineString, FloorPlanFeatureProperties> {
    return {
        id: annotation.id,
        type: 'Feature',
        geometry: {
            type: 'LineString',
            coordinates: annotation.geometry.coordinates.map((p) => projection.unProject(srsPointFromArray(p)))
        },
        properties: {
            id: annotation.id,
            // default styles
            borderColor: annotation.properties?.style?.line?.color,
            borderOpacity: annotation.properties?.style?.line?.opacity,
            borderWidth: annotation.properties?.style?.line?.width,
            // hover styles
            hoverBorderColor:
                annotation?.properties?.hoverStyle?.line?.color || annotation.properties?.style?.line?.color,
            hoverBorderOpacity:
                annotation.properties?.hoverStyle?.line?.opacity || annotation.properties?.style?.line?.opacity,
            hoverBorderWidth:
                annotation.properties?.hoverStyle?.line?.width || annotation.properties?.style?.line?.width,
            // selected styles:
            selectedBorderColor:
                annotation.properties?.selectedStyle?.line?.color || annotation.properties?.style?.line?.color,
            selectedBorderOpacity:
                annotation.properties?.selectedStyle?.line?.opacity || annotation.properties?.style?.line?.opacity,
            selectedBorderWidth:
                annotation.properties?.selectedStyle?.line?.width || annotation.properties?.style?.line?.width,
            line: true
        }
    }
}

function featureForPolygonAnnotation(
    annotation: PolygonAnnotation,
    projection: Projection = new WeSpaceProjection()
): Feature<Polygon, FloorPlanFeatureProperties> {
    const feature: Feature<Polygon, FloorPlanFeatureProperties> = {
        id: annotation.id,
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: annotation?.geometry?.coordinates?.map((pts) =>
                pts.map((p) => projection.unProject(srsPointFromArray(p)))
            )
        },
        properties: {
            label: annotation?.properties?.label,
            id: annotation.id,
            // default styles
            fillColor: annotation?.properties?.style?.fill?.color,
            fillOpacity: annotation?.properties?.style?.fill?.opacity,
            borderColor: annotation?.properties?.style?.line?.color,
            borderOpacity: annotation?.properties?.style?.line?.opacity,
            borderWidth: annotation?.properties?.style?.line?.width,
            // hover styles
            hoverFillColor:
                annotation?.properties?.hoverStyle?.fill?.color || annotation.properties?.style?.fill?.color,
            hoverFillOpacity:
                annotation.properties?.hoverStyle?.fill?.opacity || annotation.properties?.style?.fill?.opacity,
            hoverBorderColor:
                annotation?.properties?.hoverStyle?.line?.color || annotation.properties?.style?.line?.color,
            hoverBorderOpacity:
                annotation.properties?.hoverStyle?.line?.opacity || annotation.properties?.style?.line?.opacity,
            hoverBorderWidth:
                annotation.properties?.hoverStyle?.line?.width || annotation.properties?.style?.line?.width,
            // selected styles:
            selectedFillColor:
                annotation.properties?.selectedStyle?.fill?.color || annotation.properties?.style?.fill?.color,
            selectedFillOpacity:
                annotation.properties?.selectedStyle?.fill?.opacity || annotation.properties?.style?.fill?.opacity,
            selectedBorderColor:
                annotation.properties?.selectedStyle?.line?.color || annotation.properties?.style?.line?.color,
            selectedBorderOpacity:
                annotation.properties?.selectedStyle?.line?.opacity || annotation.properties?.style?.line?.opacity,
            selectedBorderWidth:
                annotation.properties?.selectedStyle?.line?.width || annotation.properties?.style?.line?.width,
            polygon: true
        }
    }
    if (annotation.properties?.underRoomLabels === true) feature.properties['underLabels'] = true
    if (annotation.properties?.label?.length || 0 > 0) feature.properties['label'] = annotation.properties.label
    return feature
}
